import React from 'react'
import { BsGrid3X3GapFill } from "react-icons/bs";
import { Container,Row,Col} from 'react-bootstrap'

   
const About = () => {
  return (
    <div>
        <div className='about-section'>
            <Container>
              <Row>
                <Col lg='6' md='7'xs='12'>
                  <div>
                    <div className='navy regular banner-title'> OUR SOLUTION</div>
                    <div className='navy-border'></div>
                    <div className='regular pb-5 fw-600 solution-title'>
                      <div>Powering A Sustainable and </div>
                      <div> Profitable Energy Transition on </div>
                      <div>Digital Solutions</div>
                    </div>
                  </div>
                </Col>
                <Col lg='6' md='5'xs='12'>
                <div className='text-center spin'>
                  <div className='wind'>
                    <img src={require('../../images/spinner-blade.webp')} alt='spinner turbine' title='spinner turbine'  className='img-fluid w-'/>
                  </div>
                    <div className='thunder-icon'>
                      <img src={require('../../images/thunder.webp')} alt='solar energy' title='solar energy'  className='img-fluid'/>
                    </div>
                    <div className='grid-solution'>
                        <div className='grid-solution-icon'><BsGrid3X3GapFill /></div>
                        <div className='regular fw-600 fs-4'> Grid Solution</div>
                    </div>
                </div>
                </Col>
                <Col lg='12'>
                <div className='banner-card'>
                    <div className='banner-about-para px-lg-5'>
                      Decarbonization requires balance <div>between legacy systems and emerging technologies.</div> <div>Between the environment and business economies. </div> 
                    </div>
                  </div>
                  <div className='banner-about'>
                    <div>
                      <img src={require('../../images/Banner.webp')} alt='spinner turbine' title='spinner turbine'  className='img-fluid '/>
                    </div>
                  </div>
                 
                </Col>
              </Row>
            </Container>
        </div>
    </div>
  )
}

export default About
