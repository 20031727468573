import React from 'react'

import { Col, Container,Row } from 'react-bootstrap'

const Counter = () => {
  return (
    <div className='counter'>
        <Container className='padding'>
            <Row>
                <Col lg='3' md='3' xs='6' className='py-3'>
                    <div className='counter-font medium text-center'>40<span className='bold fw-bold navy '>M</span></div>
                    <div className='bold text-center'>Client Satisfaction</div>
                </Col>
                <Col lg='3' md='3' xs='6' className='py-3'>
                    <div className='counter-font medium text-center'>24<span className='bold fw-bold navy '>h</span></div>
                    <div className='bold text-center'>Expert Support</div>
                </Col>
                <Col lg='3' md='3' xs='6' className='py-3'>
                    <div className='counter-font medium text-center'>98<span className='bold fw-bold navy '>k+</span></div>
                    <div className='bold text-center'>Sales Count</div>
                </Col>
                <Col lg='3' md='3' xs='6' className='py-3'>
                    <div className='counter-font medium text-center'>208<span className='bold fw-bold navy '>+</span></div>
                    <div className='bold text-center'>Client Over Country</div> 
                </Col>
            </Row>
        </Container>
        <Container className='padding'>
            <Row className='justify-content-center'>
                <Col lg='10' md='10' xs='12' className='py-3'>
                    <div className='long-banner'>
                        <div className='long-banner-content'>
                            <div className='mediumfont bold '>OUR MISSION</div>
                            <div className='regular '>Our mission is to lead the transition to renewable energy. We are dedicated to providing innovative and sustainable solutions that reduce carbon emissions and create a cleaner, greener, and more sustainable future</div>
                        </div>
                        <div >
                            <img src={require('../../images/rectangleturbine.webp')} alt='Green Energy' title='Green Energy'  className='img-fluid'/>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Counter