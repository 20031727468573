import React from 'react'
import { Container,Row,Col} from 'react-bootstrap'
const SectionBanner = () => {
  return (
    <div className='padding section'>
    <Container>
      <Row>
        <Col lg='6' className='px-4'>
          <div className='section-glass'>
              <div className='sec-desk-title'>
                <div>Partnering to drive digitization</div>
                <div> of the power sector</div>
              </div>
              <div className='sec-descript'>In partnership with some of the world’s largest utilities and energy producers, we’re helping to drive digitization. Model entire networks to help power utilities reduce costs.</div>
          </div>
        </Col>
        <Col lg='12'>
          <div>
             <img src={require('../../images/section-banner.webp')} alt='service engineer for solar energy' title='service engineer for solar energy'  className='img-fluid'/>
          </div>
        </Col>
      </Row>
    </Container>
   
    </div>
  )
}

export default SectionBanner