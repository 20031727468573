import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'

const Maintenance = () => {
  return (
    <div className='maintenance'>
        <Container  className='padding'>
            <Row className='justify-content-center'>
                <Col lg=" 12" md="12" xs="12" className='py-3'>
                    <div className='md-font navy bold'> Operation & Maintenance</div>
                </Col>
                <Col lg='5' md="5" xs="12" className='py-3 align-self-center'>
                    <div>
                        <img src={require('../../images/inspectone.webp')} alt='site maintenance' title='site maintenance'  className='img-fluid rounded'/>
                    </div>
                </Col>
                <Col lg='5' md="5" xs="12" className='align-self-center py-3'>
                    <div className='inspect-box'>
                        <div className='inspect-title'>Turbine Inspecftion</div>
                        <div className='inspct-description'>
                            Our turbine inspection services offer a comprehensive solution to enhance the efficiency and safety of your power generation assets.  From continuous monitoring for early issue detection to structural integrity assessments and performance optimization, our tailored solutions ensure compliance, reliability, and cost-efficiency. With our expertise, experience, and commitment to excellence, we stand as your trusted partner in maintaining turbine health, minimizing risks, and maximizing operational effectiveness.
                        </div>
                    </div>
                </Col>
                <Col lg='5' md="5" xs="12" className='align-self-center py-3'>
                    <div className='inspect-box'>
                        <div className='inspect-title'>Site Operation</div>
                        <div className='inspct-description'>
                            At Srivaru Green Energy, our windmill site operations epitomize excellence, combining cutting-edge technology, rigorous safety protocols, and a relentless pursuit of efficiency. With a seasoned team of professionals dedicated to proactive maintenance and customer satisfaction, we ensure seamless operations that maximize energy output while minimizing downtime and environmental impact. Choose us as your partner in sustainable energy solutions, and experience the difference of unparalleled service excellence
                        </div>
                    </div>
                </Col>
                <Col lg='5' md="5" xs="12" className='py-3 align-self-center'>
                    <div>
                        <img src={require('../../images/inspecttwo.webp')} alt='site maintenance' title='site maintenance'  className='img-fluid rounded'/>
                    </div>
                </Col>
               
                <Col lg='5' md="5" xs="12" className='py-3 align-self-center'>
                    <div>
                        <img src={require('../../images/inspectthree.webp')} alt='site maintenance' title='site maintenance'  className='img-fluid rounded'/>
                    </div>
                </Col>
                <Col lg='5' md="5" xs="12" className='align-self-center'>
                    <div className='inspect-box'>
                        <div className='inspect-title'>Spare Replacement & Repair</div>
                        <div className='inspct-description'>
                         Our windmill service offers an unparalleled spare replacement experience, ensuring seamless operations and minimal downtime. With a meticulous inventory management system and a wide range of high-quality spare parts, we guarantee swift and efficient replacements, backed by our expert technicians who prioritize precision and reliability. Trust us to keep your windmills running smoothly, maximizing productivity and optimizing your renewable energy investment
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Maintenance