import React from 'react'
import '../service/service.css'
import ServiceBanner from './servicebanner/ServiceBanner'
import Maintenance from './maintenance/Maintenance'
import Spares from './Spares/Spares'
import Others from './others/others'
import ServiceAgreements from './serviceagreement/ServiceAgreements'
const Service = () => {
  return (
    <div>
        <ServiceBanner/>
        <Maintenance/>
        <Spares/>
        <Others/>
        <ServiceAgreements/>
    </div>
  )
}

export default Service