import React from 'react'
import '../about/about.css'
import AbtBanner from './aboutbanner/AbtBanner'
import WhatWe from './whatwe/WhatWe'
import Counter from './counter/Counter'
import Experts from './experts/Experts'
import Impact from './impact/impact'
const About = () => {
  return (
    <div>
      <AbtBanner/>
      <WhatWe/>
      <Counter/>
      <Experts/>
      <Impact/>
    </div>
  )
}

export default About