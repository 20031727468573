import { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppBar from './components/appbar/AppBar';
import Footer from './components/footer/footer'
import Home from './pages/home/Home'
import About from './pages/about/About'
import { BrowserRouter, Route,Routes } from 'react-router-dom';
import Contact from './pages/contact/Contact';
import Service from './pages/service/Service';
import NoPage from './pages/nopage/NoPage';
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const timeout = setTimeout(() => {
      setLoading(false);
    },2000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    
   <>
   {loading ? (
        <div className='bg-loader'></div>
      ) : (
   <BrowserRouter>
    <AppBar/>
      <Routes>
          <Route path='/' element={<Home/>}/> 
          <Route path='/about' element={<About/>}/> 
          <Route path='/service' element={<Service/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/nopage' element={<NoPage/>}/>
      </Routes>
    <Footer/>
   </BrowserRouter>
   )}
   </>
  );
}

export default App;
