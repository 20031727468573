/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react'
import'../contact/contact.css'
import { Container,Row,Col, Button } from 'react-bootstrap'
import { MdOutlinePhone } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
const Contact = () => {
  return (
    <div>
        <div className='contact_form'>
            <Container className='padding'>
                <Row>
                    <Col lg='12' md="12" xs="12" className="py-3">
                        <div className="navy form-title bold"> Contact Us</div>
                    </Col>
                    <Col lg='7' md="12" xs="12"  className="py-3">
                        <div className='contact-image'> 
                        <div>
                            <img src={require('../images/contactpanel.webp')} alt='wind and solar power' title='wind and solar power'  className='img-fluid rounded'/>   
                        </div>
                           <div className='md-details'>
                           <div className='contactus'>
                        <div className='md-details'>
                            <div className='md-pic'>  
                                <img src={require('../images/mdpic.webp')} alt='Managing Director pics' title='Managing Director pics'  className='img-fluid'/>
                            </div>
                            <div className='md-name-mail'>
                                <div className='fw-600 medium fs-3'>Selva Raj</div> 
                                <div className='text-muted bold'>srivarugreenenergy@gmail.com</div> 
                            </div>
                            <div className='md-name'></div>
                        </div>
                        <div className='md-details align-items-center'>
                            <div className='md-icon'>  
                                 <MdOutlinePhone />
                            </div>
                            <div className='md-name-mail'>
                                <div className='fw-600 medium fs-3'> 8015200475</div>  
                            </div>
                            <div className='md-name'></div>
                        </div> <div className='md-details'>
                             <div className='md-icon'>  
                                <FaMapMarkerAlt />
                            </div>
                            <div className='md-name-mail bold '>
                                <div>219/2 vairam nagar,</div> 
                                <div>Manickapuram road,</div>
                                <div>Palladam,Tiruppur - 641664.</div>
                            </div>
                            <div className='md-name'></div>
                        </div>
                    </div>
                           </div>
                        </div>
                    </Col>
                    <Col lg='5' className='align-self-center'>
                        <div className='contact-form'>
                            <div className='form-title regular'> Contact Us</div>
                            <div className='regular'>The Big Oxmox advised her not to do so, because there were thousands of bad Commas, wild Question Marks and devious Semikoli.</div>
                            <div className='py-3 bold'>
                                <Row>
                                    <Col lg='6' md="6" xs="12" className='py-1'>
                                        <form>
                                            <label>
                                            Name
                                            <input type="text" name="name" className='p-2 w-100' />
                                            </label>
                                        </form>
                                    </Col>
                                    <Col lg='6'md="6" xs="12" className='py-1'>
                                        <form>
                                            <label>
                                            Your Email
                                            <input type="text" name="name" className='p-2 w-100' />
                                            </label>
                                        </form>
                                    </Col>
                                    <Col lg='12' md="12" xs="12"className='py-1'>
                                        <div>Your Message</div>
                                                <textarea
                                            name="postContent"
                                            rows={4}
                                            cols={40}
                                            className='w-100'
                                            />
                                    </Col>
                                    <Col lg="12">
                                        <div className='quote-btn'>
                                           <Button>Send Message</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>  
                        </div>
                    </Col>
                </Row>
            </Container>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3916.481050505327!2d77.27741727504517!3d11.002488589160352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDAwJzA5LjAiTiA3N8KwMTYnNDguMCJF!5e0!3m2!1sen!2sin!4v1713422087955!5m2!1sen!2sin" width="100%" height="500"  allowfullscreen=""  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
  )
}

export default Contact