import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'

const AbtBanner = () => {
  return (
    <div className='about-banner'>
        <Container className='padding'>
            <Row className='justify-content-center'>
                <Col lg='12'>
                    <div className='text-center my-3 py-3'> 
                        <div className='abt-title medium fw-bold'> About  Our Company</div>
                        <div className='regular fw-bold mediumfont'>Green Energy Is Our Mission</div>
                    </div>
                </Col>
                <Col lg='7' md='7' xs="12" className='py-2'>
                    <div className='abt-banner-image'>
                         <img src={require('../../images/mountainwind.webp')} alt='Turbine power' title='Turbine power'  className='img-fluid'/>       
                    </div>
                </Col>
                <Col lg='5' md="5" xs="12" className='py-2'>
                    <div className='abt-banner'>
                        <img src={require('../../images/solarwind.webp')} alt='Hybrid Energy' title='Hybrid Energy'  className='img-fluid'/> 
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default AbtBanner