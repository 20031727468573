import React from 'react'
import {Container,Row, Col ,Button} from 'react-bootstrap'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
const ServiceBanner = () => {
  return (
    <div className='service-banner'>
        <Container className='banner-pad'>
            <Row>
                <Col lg='12'>
                     <div className='title bold'> Our Service</div>
                     <div className='regular banner-description'>Srivaru Green Energy ensures that your green assets are well-maintained, providing you many years of growth and progress.</div>
                     <div className='quote-btn'><Button> Request a Quote <span><MdOutlineKeyboardArrowRight /></span></Button></div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ServiceBanner