import React from 'react';
import '../home/Home.css'
import Banner from './banner/Banner'
import OverView from './overview/OverView'
import About from './about/About'
import Benefits from './benefits/Benefits'
import SectionBanner from './sectionbanner/SectionBanner'
import Demand from './Demand/Demand'
const Home = () => {
  
  return (
    <div>
        <div>
          <Banner />
          <OverView />
          <About />
          <Benefits />
          <SectionBanner />
          <Demand />
        </div>
    </div>
  );
};

export default Home