import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { MdEnergySavingsLeaf,MdElectricBolt  } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa6";
const Experts = () => {
  return (
    <div>
        <Container className='pad'>
            <Row>
                <Col lg='6'>
                  <div className='bold fs-2 py-4 navy'>Our Expertise</div>
                    <div className='solar-cap'>   
                      <img src={require('../../images/solarcap.webp')} alt='Srivaru green energy' title='Srivaru green energy'  className='img-fluid'/>
                    </div>
                </Col>
                <Col lg='6'>
                  <div className='pos'>
                      <div className='box box-bg-one'>
                        <div className='icon-box thunder-bg text-white'><MdEnergySavingsLeaf /></div>
                        <div className='regular fw-600 box-title'>
                          <div>Sustainable Energy</div>
                          <div> Transition</div>
                        </div>
                      </div>
                    <div className='box box-blu-one'>
                        <div className='icon-box   sunny-bg'><MdElectricBolt /></div>
                        <div className='regular fw-600 box-title'>
                          <div>Reliable Energy</div>
                          <div> Transition</div>
                        </div>
                    </div>
                    <div className='mockup-wind'> 
                      <img src={require('../../images/windmill.webp')} alt='Srivaru green energy' title='Srivaru green energy'  className='img-fluid'/>
                      </div>
                    <div className='box box-blue-one'>
                        <div className='icon-box  sunny-bg'><FaFileInvoiceDollar /></div>
                        <div className='regular fw-600 box-title'>
                          <div>Affordable Energy</div>
                          <div> Transition</div>
                        </div>
                    </div>
                  </div>
                
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Experts
