import React from 'react'
import { Container,Row,Col} from 'react-bootstrap'
import { LuGlobe } from "react-icons/lu";
const Banner = () => {
  return (
    <div>
        <div className='home-banner padding'>
            <Container>
              <Row className='white'>
                <Col lg="7" md='6' xs='12' className='py-3 align-self-center'>
                  <div className='banner-text medium px-lg-5 mx-lg-5 mt-lg-5 pt-lg-5'>
                      <div className='  largefont pt-lg-5 mx-5'>
                        <div className='banner-title '>RENEWABLE ENERGY</div>
                        <div className='navy  fw-bold bold'>Digital in the</div>
                        </div>
                      <div className='bold navy largefont fw-bold ms-5 wordbreak'>Future of Energy</div>
                  </div>
                </Col>
                <Col lg="5" md='6' xs='12' className='py-3 align-self-center'>
                  <div className='glass-morphi my-3'>
                    <div>
                      <div className='glass-title medium navy mediumfont'> Enable reliable, cost effective</div>
                      <div className='glass-title medium navy mediumfont'> and dispatchable power</div>
                    </div>
                      
                      <div className='morphi-title text-white medium py-2'>
                        Sun Energy has accumulated more than 9 gigawatts of total global installed base and backlog for its inverter      technology...
                    </div>
                  </div>
                  <div className='w-75 mx-auto'>
                    <div className='save-earth bold'>
                        <span className='globe'><LuGlobe /></span>
                        <span className='save-earth-title'>Save Earth</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className='justify-content-center py-3'>
                <Col lg='9' md='9' xs='12'>
                  <div className='bottom-content'>
                     <div className='bottom-glass-morphi'>
                      <div className='text-center'>
                        <div className='navy bold fs-1'>10M+</div>
                        <div className='text-white regular mediumfont fw-600'>
                          <div >Worldwide product</div>
                          <div> sales per year</div>
                        </div>
                      </div>
                      <div className='morphi-solar my-3'>
                          <img src={require('../../images/bottommorphic.webp')} alt='hydro power' title='hydro power'  className='img-fluid'/>
                      </div>
                    </div>
                    <div className='banner-bottom regular'>
                        <div>Reducing carbon and costs while providing sustainable,</div>
                        <div>reliable, and affordable power</div> 
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
        </div>
    </div>
  )
}

export default Banner