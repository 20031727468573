import React from 'react'
import {Container,Row,Col} from "react-bootstrap"
const Demand = () => {
  return (
    <div className='padding demands'>
        <Container>
            <Row>
                <Col lg='6' className='py-3'>
                    <Row>
                        <Col lg='7'>
                            <div className='demand'>
                                <div className='spinner-team'>
                                    <img src={require('../../images/vertical-turbine.webp')} alt='Turbine Energy' title='Turbine Energy'  className='img-fluid'/>
                                </div>
                                <div className='square-panel'>
                                    <div className='numb-panel'>01</div>
                                    <div>How this new asset mix and decentralization trend is changing the operation and maintenance of these assets</div>
                                </div>
                                <div className='square-panel-one'>
                                    <div className='numb-panel'>02</div>
                                    <div>Managing O&M cost and maintaining other KPI’s while empowering the workforce.</div>
                                </div>
                            </div>
                            
                        </Col>
                        <Col lg='5'>
                            <div className='solar-pan'>
                                <img src={require('../../images/solarpanel.webp')} alt='Turbine Energy' title='Turbine Energy'  className='img-fluid rounded'/>
                            </div>
                        </Col>
                        <Col lg='10'>
                            
                        </Col>
                    </Row>
                </Col>
                <Col lg='1'></Col>
                <Col lg='5' className='px-lg-0 px-3'>
                    <div className='banner-title regular fw-600 pb-4'>
                    Power Magazine on-demand webinar with professionals
                    </div>
                    <div className='regular inspect-deatils'>
                    Watch as industry expert John Korsedal, GE Digital, discusses the impact of decarbonization in the power generation industry and how these trends are driving a mix of more decentralized assets over large geographic areas.
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Demand