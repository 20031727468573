import React from 'react'
import { Button } from 'react-bootstrap'

const LoginButton = ({label , onClick}) => {
  return (
    <div>
        <Button onClick={onClick}>{label}</Button>
    </div>
  )
}

export default LoginButton