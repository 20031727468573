import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsQuestion } from "react-icons/bs";
const WhatWe = () => {
  return (
    <div className='what-we'>
        <Container className='padding'>
            <Row>
                <Col lg='5' md="12" xs="12">
                    <div className='what-we'>
                        <div>
                            <img src={require('../../images/recturbine.webp')} alt='wind Power' title='wind power'  className='img-fluid'/> 
                        </div>
                       <div className='what-we-morphi'>
                            <div className="text-center"><BsQuestion /></div>
                            <div className='bold mediumfont'>What We Do</div>
                       </div>
                    </div>
                      
                </Col>
                <Col lg='7' className='align-self-center'>
                    <div className='regular'>
                        Our Company is a leading provider of renewable energy solutions, with a strong focus on wind and solar energy. We are committed to creating a sustainable future by harnessing the power of the wind and the sun to provide clean, reliable, and affordable energy. Our journey began with a simple idea: to make renewable energy accessible and affordable for everyone. We believe that a sustainable future is not just possible, but necessary, and we are dedicated to making this vision a reality.
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default WhatWe