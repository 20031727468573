import React from 'react'
import { Container,Row,Col, Button } from 'react-bootstrap'
const NoPage = () => {
  return (
    <>
<Container className='pad'>
    <Row>
        <Col lg="12">
            <div className='text-center bold'>
                <img src={require('../../../src/images/errorpage.jpg')} alt='404 page' title='404 page'  className='img-fluid'/>
                <h2>Page Not Found</h2>
            </div>
            <div className='bold home'>
                <Button> Go to Home Page</Button>
            </div>
        </Col>
    </Row>
</Container>
    </>
  )
}

export default NoPage