import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'

const Spares = () => {
    return (
        <div>
            <Container fluid='lg' className='pad'>
                <Row className='justify-content-center'>
                    <Col lg='12' className='py-2'>
                        <div className='Spares-title'>
                            <div className=''>
                                <h3 className='bold navy md-font'>Major Spares Replacement </h3>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4' className='py-2'>
                        <div className='Spares-box'>
                            <div className='text-center Spares-box-image mx-auto'>
                                <img src={require('../../images/nacele.webp')} className='img-fluid w-100 ' alt='' title=''></img>
                            </div>
                            <div className='text-end'>
                                <h2 className='bold  navy'>Nacelle</h2>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4' className='py-2'>
                        <div className='Spares-box'>
                            <div className='text-center Spares-box-image mx-auto'>
                                <img src={require('../../images/blades.webp')} className='img-fluid w-100 ' alt='' title=''></img>
                            </div>
                            <div className='text-end'>
                                <h2 className='bold  navy'>Blades</h2>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4' className='py-2'>
                        <div className='Spares-box'>
                            <div className='text-center Spares-box-image mx-auto'>
                                <img src={require('../../images/bearings.webp')} className='img-fluid w-100' alt='' title=''></img>
                            </div>
                            <div className='text-end'>
                                <h2 className='bold  navy'>Main Bearing</h2>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4' className='py-2'>
                        <div className='Spares-box'>
                            <div className='text-center Spares-box-image mx-auto'>
                                <img src={require('../../images/gearbox.webp')} className='img-fluid w-100' alt='' title=''></img>
                            </div>
                            <div className='text-end'>
                                <h2 className='bold  navy'>Gear Box</h2>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4' className='py-2'>
                        <div className='Spares-box'>
                            <div className='text-center Spares-box-image mx-auto'>
                                <img src={require('../../images/generator.webp')} className='img-fluid w-100' alt='' title=''></img>
                            </div>
                            <div className='text-end'>
                                <h2 className='bold  navy'>Generator</h2>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4' className='py-2'>
                        <div className='Spares-box'>
                            <div className='text-center Spares-box-image mx-auto'>
                                <img src={require('../../images/Power Transformer.webp')} className='img-fluid w-100' alt='' title=''></img>
                            </div>
                            <div className='text-end'>
                                <h2 className='bold  navy'>Power Transformer</h2>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Spares