import React from 'react'
import { Container,Row,Col} from 'react-bootstrap'
import { MdEnergySavingsLeaf,MdElectricBolt  } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa6";
const Benefits = () => {
  return (
    <div className='padding benefits-section'>
      <div className='turbine-paralax pad'>
      </div>
      <Container fluid className='px-lg-0 px-2'>
        <Row>
          <Col lg='6' className='py-3'>
            <div className='service-page'>
              <div className='px-md-3'>
                <img src={require('../../images/service-engineer.webp')} alt='service engineer for solar energy' title='service engineer for solar energy'  className='img-fluid'/>
              </div>
              <div className='box box-bg'>
                  <div className='icon-box thunder-bg text-white'><MdEnergySavingsLeaf /></div>
                  <div className='regular fw-600 box-title'>
                    <div>Sustainable Energy</div>
                    <div> Transition</div>
                  </div>
              </div>
              <div className='box box-blu'>
                  <div className='icon-box   sunny-bg'><MdElectricBolt /></div>
                  <div className='regular fw-600 box-title'>
                    <div>Reliable Energy</div>
                    <div> Transition</div>
                  </div>
              </div>
              <div className='box box-blue'>
                  <div className='icon-box  sunny-bg'><FaFileInvoiceDollar /></div>
                  <div className='regular fw-600 box-title'>
                    <div>Affordable Energy</div>
                    <div> Transition</div>
                  </div>
              </div> 
            </div>
          </Col>
          <Col lg='1'></Col>
          <Col lg='5' className='align-self-center'>
            <div className='service-para'>
              <div className='para-title'>Benifits</div>
              <div className='green-border'></div>
              <div className='sub-title'>
                <div>Solving the energy</div>
                <div>transition over country</div>
              </div>
              <hr/>
                <div className='service-text'>
                  Read Digital's new white paper on sustainability and profitability and understand how you can unlock balanced energy and business models while better managing asset risk and carbon emissions.
                </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Benefits