import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { GiWindTurbine } from "react-icons/gi";
import { TbBuildingFactory2 } from "react-icons/tb";
import { PiEngine } from "react-icons/pi";
import { FaBoxOpen } from "react-icons/fa";
import { HiOutlineChip } from "react-icons/hi"
import { BsGraphUpArrow } from "react-icons/bs"
const ServiceAgreements = () => {
  return (
    <div>
        <Container fluid>
          <Row>
            <Col lg='12' md='12' xs='12' className='py-3'>
                  <div className='Spares-title bold spare md-font navy'> Service Agreement</div>
            </Col>
          </Row>
        </Container>
        <div className='bg-agreement'>
            <Container fluid className='padding'>
              <Row>
                <Col lg="4" md="4"  xs="12">
                    <Row>
                      <Col lg="12" md="12" xs="12" className='py-3'>
                          <div className=' sa-details'>
                              <div className='sa-icon'><GiWindTurbine /></div>
                              <div className='sa-head'>Up tower and  On-site Repair  </div>
                              <div className='sa-para'>Repair Kits or Full Installaition</div>
                          </div>
                      </Col>
                       <Col lg="12" md="12" xs="12" className='py-3'>
                          <div className=' sa-details'>
                              <div className='sa-icon'><TbBuildingFactory2 /></div>
                              <div className='sa-head'>Works shop Repair</div>
                              <div className='sa-para'>The Broadest repair program delivered</div>
                          </div>
                      </Col>
                      <Col lg="12" md="12" xs="12" className='py-3'>
                          <div className=' sa-details'>
                              <div className='sa-icon'><PiEngine /></div>
                              <div className='sa-head'>Replacement Units </div>
                              <div className='sa-para'>Fast Delivery  through  new  and pre refurbished gearboxes</div>
                          </div>
                      </Col>
                    </Row>
                </Col>
                <Col lg="4" md="4" xs="12" className='align-self-center'> 
                <div className='text-center mx-auto'>
                    <img src={require('../../images/samotor.webp')} alt='turbine motor' title='turbine motor'  className='img-fluid rounded'/>
                </div>
                </Col>
                <Col lg="4" md="4" xs="12">
                    <Row>
                      <Col lg="12" md="12" xs="12" className='py-3'>
                          <div className=' sa-details text-lg-end text-left'>
                              <div className='sa-icon'><BsGraphUpArrow /></div>
                              <div className='sa-head'>Insight Partner</div>
                              <div className='sa-para'>Root Cause analysis , helpdesk and predective analytics</div>
                          </div>
                      </Col>
                       <Col lg="12" md="12" xs="12" className='py-3'>
                          <div className=' sa-details text-lg-end text-left'>
                              <div className='sa-icon'><HiOutlineChip /></div>
                              <div className='sa-head'>Intelligent gearbox retrofits</div>
                              <div className='sa-para'>The benifits of intelligent gearbox on existing fleet</div>
                          </div>
                      </Col>
                      <Col lg="12" md="12" xs="12" className='py-3'>
                          <div className=' sa-details text-lg-end text-left'>
                              <div className='sa-icon'><FaBoxOpen /></div>
                              <div className='sa-head'>Spare Parts</div>
                              <div className='sa-para'>when Individual parts are needed</div>
                          </div>
                      </Col>
                    </Row>
                </Col>
              </Row>
            </Container>
        </div>
    </div>
  )
}

export default ServiceAgreements