import React from 'react'
import { Container,Row,Col} from 'react-bootstrap'
import { GiWindmill } from "react-icons/gi";
const  OverView = () => {
  return (
    <div className='overview-container'>
    <div className='pad over-view-container'>
        <Container>
          <Row>
            <Col lg='6' md='8' xs='12'>
              <Row>
                  <Col lg='6' md='8' xs='12'>
                      <div className='air-turbine'>
                          <img src={require('../../images/airdrone.webp')} alt='Turbine Energy' title='Turbine Energy'  className='img-fluid'/>
                      </div>
                      <div className=""></div>
                  </Col>
                  <Col lg='6' md='4' xs='12'>
                    <Row>
                      <Col lg='12' md='12' xs='12'>
                        <div className='overview-title fw-600 regular'>
                            <div className='wordbreak'>
                              Decorbonization  is mission,
                            </div>
                            <div className='wordbreak'>
                              Critical for the Planet
                            </div>
                        </div>
                      </Col>
                    </Row>
                    <div className='overview' >
                      <div className='air-box text-center'>
                        <div className='wind-mill '>
                             <GiWindmill />
                        </div>
                        <div className='fw-600 regular text-white'>Wind Power</div>
                      </div>
                      <div className="pre-profile">
                          <img src={require('../../images/workship.webp')} alt='Solar Inspections' title='Solar Inspections'  className='img-fluid'/>
                      </div>
                    </div>
                  </Col>
                </Row>
            </Col>
            <Col lg="5" className='align-self-center'>
              <div className='over-view-para'>
                <div className='py-2'>
                  The global energy landscape will change more in the next 10 years than in the previous hundred. As the world’s energy sector moves away from fossil fuels toward renewable energy sources, industrial companies are challenged with addressing this transition in transformative ways.
                </div>
                <div className='py-2'>
                Renewable Energy harnesses the earth’s most abundant resources – the strength of the wind, the heat of the sun, ,and the force of water; delivering green electrons to power the world’s biggest economies and the most remote communities.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        </div>
    </div>
  )
}

export default OverView